<div class="swipe-wrap">
  <div class="mb-2 d-flex align-items-center">
    <h1 class="h3">Ratings</h1>
    <div class="text-end flex-grow-1">
      <button type="button" class="btn btn-text" (click)="onToggleFilters()"><i class="fas fa-filter"></i></button>
    </div>
  </div>

  <p *ngIf="!(brews$| async)?.length">No Brews</p>

  <div class="row gy-3">
    <div class="col-12" *ngFor="let b of (brews$| async); let i = index" #brew>
      <div class="card swipe-card" (swipeleft)="onSwipeLeft($event)" (swiperight)="onSwipeRight($event)">

        <div *ngIf="b.user_id === (currentUser$ | async)?.id"
          class="swipe-card__controls position-absolute top-50 end-0 translate-middle-y d-none align-items-center"
          style="height: 100%">
          <button type="button" class="btn btn-link" (click)="onEdit(b.id)"><i class="far fa-edit fa-fw"></i></button>
          <button type="button" class="btn btn-link" (click)="onDelete(b.id)"><i class="far fa-trash-alt fa-fw"></i></button>
        </div>

        <div class="card-body">
          <div class="card-title mb-0">

            <a class="d-block link-dark p-0 text-decoration-none" data-bs-toggle="collapse" href="#collapse-{{i}}">
              <ng-container *ngIf="getBean(b.bean_id) | async as bean">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <div class="fs-8">{{ b.created_at | date:"MMM d, y" }}</div>
                    <div class="text-uppercase fw-light fs-6">{{ bean.brand }}</div>
                    <div class="fs-7">{{ bean.name ? bean.name : bean.origin }}</div>
                  </div>
                  <div class="d-flex flex-column align-items-end">
                    <span class="badge bg-dark fs-7">{{ getRoast(bean.roast) }} Roast</span>
                    <div>
                      <app-stars [rating]="getAvgRating(b)"></app-stars>
                    </div>
                  </div>
                </div>
              </ng-container>
            </a>
          </div>

          <div class="collapse mt-3" id="collapse-{{i}}">
            <p class="card-text">
              Method: {{ (getMethod(b.method_id) | async)?.type }}<br/>
              Grams: {{ b.grams }}g <span *ngIf="b?.grind !== null">({{ (b.grind !== undefined && b.grind > -1 !== null ? Grind[b.grind] : 'Unknown') | sentenceCase}})</span>
            </p>
            <ng-container *ngIf="b.notes">
              <p class="bg-light p-2 mb-0 rounded shadow-sm">
                Notes:<br/>
                {{ b.notes }}
              </p>
            </ng-container>

            <hr/>
            <div class="mb-4 d-flex">
              <div class="flex-grow-1">Ratings</div>
              <div><button type="button" class="btn btn-coffee btn-sm" (click)="onAddRating(b.id)">
                <i class="fas fa-plus-circle"></i>
              </button></div>
            </div>

            <ng-container *ngIf="b.ratings != null && b.ratings.length > 0; else noRatings">
              <div class="card" *ngFor="let r of b.ratings; let i = index" [class.mb-3]="b.ratings.length > 1 && i + 1 < b.ratings.length">
                <div class="d-flex align-items-top card-body">
                  <div class="circle me-4">
                    <span class="initials">{{ r?.user?.name | userInitials}}</span>
                  </div>
                  <div class="flex-grow-1">
                    <div class="fs-8">{{ r.created_at | date:"MMM d, y" }}</div>
                    <table>
                      <tr>
                        <td>Rating:</td>
                        <td>
                          <app-stars [rating]="r.rating"></app-stars>
                        </td>
                      </tr>
                      <tr>
                        <td>Aroma:</td>
                        <td>
                          <app-stars [rating]="r.aroma"></app-stars>
                        </td>
                      </tr>
                      <tr>
                        <td>Body:</td>
                        <td>
                          <app-stars [rating]="r.body"></app-stars>
                        </td>
                      </tr>
                      <tr>
                        <td>Acidity:</td>
                        <td>
                          <app-stars [rating]="r.acidity"></app-stars>
                        </td>
                      </tr>
                      <tr>
                        <td>Sweetness:</td>
                        <td>
                          <app-stars [rating]="r.sweetness"></app-stars>
                        </td>
                      </tr>
                      <tr>
                        <td>Aftertaste:</td>
                        <td>
                          <app-stars [rating]="r.aftertaste"></app-stars>
                        </td>
                      </tr>
                    </table>
                    <ng-container *ngIf="r.notes">
                      <p class="bg-light p-2 mb-0 me-3 rounded shadow-sm">
                        Notes:<br/>
                        {{ r.notes }}
                      </p>
                    </ng-container>
                  </div>
                  <div>
                    <button type="button"
                      *ngIf="r.user_id === (currentUser$ | async)?.id"
                      class="btn btn-coffee btn-sm p-1"
                      (click)="onEditRating(b.id, r.id)">
                      <i class="fas fa-ellipsis-v fa-fw"></i>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noRatings>
              <div class="card"><div class="d-flex align-items-top card-body">No Ratings.</div></div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="position-fixed bottom-0 end-0 h1 m-3 rounded-circle align-items-center justify-content-center d-flex shadow-lg"
    style="height: 55px; width: 55px; cursor: pointer; background: #fff">
    <i class="fas fa-plus-circle d-block" (click)="onAddBrew()"></i>
  </div>
</div>
