<form [formGroup]="form" class="m-4" (ngSubmit)="onSave()">
  <div class="mb-3">
    <label for="bean_id" class="form-label">Bean</label>
    <select id="bean_id" class="form-control" formControlName="bean_id"
      [class.is-invalid]="form.get('bean_id')?.invalid && form.get('bean_id')?.touched">
      <option></option>
      <option [title]="getTitle(bean)" *ngFor="let bean of (beans$ | async)" [ngValue]="bean.id">{{getLabel(bean)}}</option>
    </select>
  </div>
  <div class="mb-3">
    <label for="method_id" class="form-label">Method</label>
    <select id="method_id" class="form-control" formControlName="method_id"
      [class.is-invalid]="form.get('method_id')?.invalid && form.get('method_id')?.touched">
      <option></option>
      <option *ngFor="let method of (methods$ | async)" [ngValue]="method.id">{{ method.type }} ({{ method.volume }}{{ method.units}})</option>
    </select>
  </div>

  <div class="mb-3">
    <label for="grams" class="form-label">Grams</label>
    <input type="number" id="grams" class="form-control" formControlName="grams"
      [class.is-invalid]="form.get('grams')?.invalid && form.get('grams')?.touched" />
  </div>

  <div class="mb-3">
    <label for="grind" class="form-label">Grind</label>
    <select id="grind" class="form-control" formControlName="grind"
      [class.is-invalid]="form.get('grind')?.invalid && form.get('grind')?.touched">
      <option></option>
      <option *ngFor="let kvp of (Grind | enumToArray)" [ngValue]="kvp.key">{{ kvp.value | sentenceCase }}</option>
    </select>
  </div>

  <div class="mb-3">
    <label for="notes" class="form-label">Notes</label>
    <textarea id="notes" class="form-control" formControlName="notes" rows="4" maxlength="255"
      [class.is-invalid]="form.get('notes')?.invalid && form.get('notes')?.touched"></textarea>
  </div>

  <div class="d-grid gap-2">
    <button class="btn btn-primary" [disabled]="form.invalid">Save</button>
    <button type="button" class="btn btn-outline-primary" (click)="onClose()">Close</button>
  </div>
</form>
