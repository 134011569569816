<form [formGroup]="form" class="m-4" (ngSubmit)="onSave()">
  <div class="mb-3">
    <label for="rating" class="form-label">Rating *</label>
    <div><app-stars-input formControlName="rating"></app-stars-input></div>
  </div>

  <div class="mb-3" *ngFor="let key of optionalKeys">
    <label [attr.for]="key" class="form-label">{{key | titlecase}}</label>

    <button type="button"
      class="btn btn-sm"
      placement="end"
      [ngbPopover]="optionalDescriptions[key]" [popoverTitle]="key">
        <i class="fa fa-solid fa-info-circle" aria-hidden="true"></i>
        <span class="visually-hidden">info icon</span>
    </button>

    <div><app-stars-input [formControlName]="key"></app-stars-input></div>
  </div>

  <div class="mb-3">
    <label for="notes" class="form-label">Notes</label>
    <textarea id="notes" class="form-control" formControlName="notes" rows="4" maxlength="255"
      [class.is-invalid]="form.get('notes')?.invalid && form.get('notes')?.touched"></textarea>
  </div>

  <div class="d-grid gap-2">
    <button class="btn btn-primary" [disabled]="form.invalid">Save</button>
    <button type="button" class="btn btn-outline-primary" (click)="onClose()">Close</button>
  </div>
</form>
